import React, { useState } from "react"

const getAllExperimentStatus = () => {
  if (typeof document === "undefined") return {}

  const cookie = document.cookie
    .split("; ")
    .find(row => row.startsWith("experiments="))
    ?.split("=")[1]

  if (!cookie) return {}

  return JSON.parse(decodeURIComponent(cookie))
}

const useForceUpdate = () => {
  const [b, setB] = useState()
  return () => setB(!b)
}

const AllExperimentsStatus = () => {
  const experiments = getAllExperimentStatus()
  const forceUpdate = useForceUpdate()

  const updateTest = ev => {
    ev.preventDefault()
    const name = ev.target.querySelector('[name="exp"]').value
    const value =
      ev.target.querySelector('[name="group"]').value === "VARIATION"

    const newCookie = encodeURIComponent(
      JSON.stringify({ ...experiments, [name]: value })
    )
    document.cookie = `experiments=${newCookie};path=/`

    forceUpdate()
  }

  const deleteTest = name => {
    const newCookie = encodeURIComponent(
      JSON.stringify({ ...experiments, [name]: undefined })
    )
    document.cookie = `experiments=${newCookie};path=/`
    forceUpdate()
  }

  return (
    <div>
      <ul>
        {Object.keys(experiments).map(exp => (
          <li key={exp}>
            <form onSubmit={updateTest}>
              <input disabled name="exp" value={exp} size="64" />
              <select
                name="group"
                defaultValue={experiments[exp] ? "VARIATION" : "CONTROL"}
              >
                <option value="CONTROL">CONTROL</option>
                <option value="VARIATION">VARIATION</option>
              </select>
              <input type="submit" value="ok" />
              <button onClick={() => deleteTest(exp)}>delete</button>
            </form>
          </li>
        ))}
        <li>
          <form onSubmit={updateTest}>
            <input
              name="exp"
              size="64"
              placeholder="nwexp-redesign-XXXX-YYYY"
            />
            <select name="group">
              <option value="CONTROL">CONTROL</option>
              <option value="VARIATION">VARIATION</option>
            </select>
            <input type="submit" value="ok" />
          </form>
        </li>
      </ul>
    </div>
  )
}

const Page = () => {
  return (
    <div>
      <h1>ABTest Page</h1>
      <AllExperimentsStatus />
    </div>
  )
}

export default Page
